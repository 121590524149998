<template>
  <MainLayout
    v-if="issue"
    back-to="/manage/support/queue/unresolved"
    :back-to-label="$t('admin.helpdesk')"
    :title="issue.subject"
    :short-title="issue.key"
    has-sidebar
  >
    <template v-slot:content>
      <div class="is-hidden-mobile has-margin-top has-margin-bottom is-flex">
        <div>
          <faicon icon="user-circle" size="3x" class="has-margin-right-small"></faicon>
        </div>
        <div>
          <p class="is-size-3">{{ issue.user.name }}</p>
          <p class="is-meta">{{ issue.user.email }}</p>
        </div>
      </div>
      <div class="is-hidden-tablet is-flex is-flex-align-center has-margin-bottom has-margin-top">
        <div class="has-margin-right is-flex-grow">
          <span class="label">Requerente</span>
          <faicon icon="user-circle" class="has-margin-right-small"></faicon>{{ issue.user.name }}
        </div>
        <div class="has-margin-right">
          <span class="label">{{ $t('support.id') }}</span>
          <span>#{{ issue.key }}</span>
        </div>
        <div class="has-margin-right">
          <span class="label">{{ $tc('procedure.label') }}</span>
          <span>{{ issue.procedure.code }}</span>
        </div>
        <div>
          <span class="label">{{ $t('date') }}</span>
          <span>{{ issue.created_date | formatDateTime }}</span>
        </div>
      </div>
      <div v-if="issue" class="panel">
        <div class="panel-header is-hidden-tablet is-flex is-flex-align-bottom is-flex-space-between">
          <div>
            <span class="label">{{ $t('support.status.title') }}</span>
            <b-dropdown v-model="selectedStatus" class="is-block" aria-role="list" @change="updateIssueStatus">
              <button
                slot="trigger"
                class="button"
                :class="{
                  'is-black': selectedStatus.key == 'closed',
                  'is-primary': selectedStatus.key == 'open',
                  'is-warning': selectedStatus.key == 'new',
                }"
                type="button"
              >
                <template>
                  <span>{{ selectedStatus.title }}</span>
                </template>
                <b-icon icon="caret-down"></b-icon>
              </button>

              <b-dropdown-item
                v-for="status in issueAvailableStatus"
                :key="status.key"
                :value="status"
                aria-role="listitem"
              >
                <div class="media">
                  <div class="media-content">{{ status.title }}</div>
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div v-if="$store.getters.userPermissions.isProcedureManager">
            <button class="button is-primary is-outlined" @click="openNewReply">{{ $t('actions.reply') }}</button>
          </div>
        </div>
        <div class="panel-body box">
          <div class="content" v-html="issue.description"></div>
          <div v-if="issue.files.length" class="panel-body">
            <div class="label has-margin-bottom">{{ $t('support.attachments') }}</div>
            <RecordFile v-for="file in issue.files" :key="file.id" :file="file" @download="downloadIssueFile" />
          </div>
        </div>
      </div>
      <div v-if="issue" class="panel">
        <div class="panel-header is-flex is-flex-align-center is-flex-space-between">
          <div>
            <h2>{{ $tc('support.messages.title', 2) }}</h2>
          </div>
          <div>
            <button class="button is-text" @click="openNewReply">
              {{ $t('support.messages.new') }}
            </button>
          </div>
        </div>
        <div class="panel-body">
          <div v-if="issue.messages.length">
            <div v-for="message in issue.messages" :key="message.key" class="record">
              <div v-if="!message.status && message.description" class="record-content">
                <div class="record-header has-margin-bottom">
                  {{ $t('support.messages.replyFrom') }} <strong>{{ message.user.name }}</strong> {{ $t('in') }}
                  {{ message.created_date | formatDateTime }}
                </div>
                <div class="indent">
                  <div class="content" v-html="message.description"></div>
                  <div v-if="message.files.length">
                    <div class="label has-margin-bottom">{{ $t('support.attachments') }}</div>
                    <RecordFile
                      v-for="file in message.files"
                      :key="file.id"
                      :file="file"
                      @download="downloadMessageFile"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="indent is-meta">
                {{ $t('support.status.changed') }} <strong>{{ getStatusByKey(message.status).title }}</strong>
                {{ $t('by') }} {{ message.user.name }} {{ $t('in') }}
                {{ message.created_date | formatDateTime }}
              </div>
            </div>
            <div class="has-margin-top-medium has-margin-bottom">
              <button class="button is-outlined is-primary" @click="openNewReply">
                {{ $t('support.messages.new') }}
              </button>
            </div>
          </div>
          <div v-else class="box has-text-centered is-meta">{{ $t('support.messages.noReply') }}.</div>
        </div>
      </div>
    </template>
    <template v-slot:sidebar>
      <div>
        <div>
          <span class="label">{{ $t('support.status.title') }}</span>
          <b-dropdown v-model="selectedStatus" class="is-block" aria-role="list" @change="updateIssueStatus">
            <button
              slot="trigger"
              class="button is-size-3"
              :class="{
                'is-black': selectedStatus.key == 'closed',
                'is-primary': selectedStatus.key == 'open',
                'is-warning': selectedStatus.key == 'new',
              }"
              type="button"
            >
              <template>
                <span>{{ selectedStatus.title }}</span>
              </template>
              <b-icon icon="caret-down"></b-icon>
            </button>

            <b-dropdown-item
              v-for="status in issueAvailableStatus"
              :key="status.key"
              :value="status"
              aria-role="listitem"
            >
              <div class="media">
                <div class="media-content">{{ status.title }}</div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="has-margin-top-medium">
          <span class="label">{{ $t('support.id') }}</span>
          <span class="is-size-2">#{{ issue.key }}</span>
        </div>
        <div class="has-margin-top-medium">
          <span class="label">{{ $tc('procedure.label') }}</span>
          <span>{{ issue.procedure.code }}</span>
        </div>
        <div class="has-margin-top-medium">
          <span class="label">{{ $t('date') }}</span>
          <span class="is-meta">{{ issue.created_date | formatDateTime }}</span>
        </div>
        <div v-if="$store.getters.userPermissions.isProcedureManager" class="has-margin-top-large">
          <button class="button is-primary is-outlined" @click="openNewReply">{{ $t('actions.reply') }}</button>
        </div>
      </div>
    </template>
    <template v-slot:modals>
      <ModalAddEditIssueMessage :issue="issue" :show.sync="isNewReplyModalActive" @close="closeNewReplyModal" />
    </template>
  </MainLayout>
</template>

<script>
import SupportService from '@/services/SupportService'
// import AuthService from '@/services/AuthService'

import MainLayout from '@/components/layouts/MainLayout'
import RecordFile from '@/components/records/RecordFile'
import ModalAddEditIssueMessage from '@/components/modals/ModalAddEditIssueMessage'
// import UserPhoto from '@/components/user/UserPhoto'

export default {
  components: {
    MainLayout,
    RecordFile,
    ModalAddEditIssueMessage,
    // UserPhoto,
  },
  data() {
    return {
      issue: null,
      isNewReplyModalActive: false,
      selectedStatus: null,
      issueAvailableStatus: [
        { key: 'new', title: this.$t('support.status.new') },
        { key: 'open', title: this.$t('support.status.open') },
        { key: 'closed', title: this.$t('support.status.closed') },
      ],
    }
  },
  mounted() {
    this.getIssue()
  },
  methods: {
    updateIssueStatus(selected) {
      SupportService.updateIssueStatus({
        key: this.issue.key,
        status: selected.key,
      }).then(issue => {
        this.getIssue(issue)
        this.$buefy.snackbar.open({
          message: 'O estado do pedido foi atualizado',
          type: 'is-primary',
          queue: false,
          duration: 5000,
        })
      })
    },
    openNewReply() {
      this.isNewReplyModalActive = true
    },
    getStatusByKey(key) {
      return this.issueAvailableStatus.filter(e => {
        if (e.key == key) {
          return e
        }
      })[0]
    },
    closeNewReplyModal(reload) {
      if (reload) {
        this.getIssue()
      }
      this.isNewReplyModalActive = false
    },
    async getIssue(issue = null) {
      this.loading = true
      if (issue) {
        this.issue = issue
      } else {
        this.issue = await SupportService.getIssue(this.$route.params.key)
      }
      this.selectedStatus = this.getStatusByKey(this.issue.status)
      this.loading = false
    },
    downloadIssueFile(file) {
      SupportService.downloadIssueFile(file.key, file.filename)
    },
    downloadMessageFile(file) {
      SupportService.downloadMessageFile(file.key, file.filename)
    },
  },
}
</script>
